import React from 'react'
import { Content } from './styles'
import Flag from '../../../assets/flag.png'

export default function Flags() {
  return (
    <Content>
      <div>
        <h1>+ 28.000</h1>
        <p>VAGAS SINALIZADAS</p>
      </div>
      <div>
        <h1>+ 80.000</h1>
        <p>VEÍCULOS MONITORADOS POR DIA</p>
      </div>
      <div>
        <h1>20</h1>
        <p>ESTABELECIMENTOS</p>
      </div>
      <div>
        <img src={Flag} alt='' />
      </div>
    </Content>
  )
}
