import React, { useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { IoMdClose } from 'react-icons/io'
import { Mobile, MobileShow } from './styles'
import { Link } from 'react-router-dom'

export default function HeaderMobile() {
  const [showMobile, setShowMobile] = useState(false)
  console.log(showMobile)

  const toggleMenu = () => {
    setShowMobile(!showMobile)
  }
  const closeMenu = () => {
    setShowMobile(false);
  };

  return (
    <>
    <Mobile>
      <button type='button' onClick={toggleMenu}>
        {showMobile ? <IoMdClose /> : <RxHamburgerMenu />}
      </button>
    </Mobile>
    {showMobile && (
      <MobileShow 
        isOpen={showMobile} 
        onClick={closeMenu} 
        onKeyDown={closeMenu}
        role="presentation"
      >
        <Link to='/'>INÍCIO</Link>
        <Link to='/a-pollipark'>A POLLIPARK</Link>
        <Link to='/solucao'>SOLUÇÃO</Link>
        <Link to='https://api.whatsapp.com/send?phone=554888359478'>SUPORTE</Link>
        <Link to='/galeria'>GALERIA</Link>
        <Link to='#' className='linka'>
          <p>SOLICITAR PROPOSTA</p>
        </Link>
      </MobileShow>
    )}
  </>
);
}
