import React from 'react'
import { ServiceMain } from '../styles'
import Check from '../../../assets/slider/check.svg'
import Camera from '../../../assets/carside_parked.png'

export default function Services() {
  return (
    <>
      <ServiceMain>
        <div className='left'>
          <h1>Um jeito seguro de estacionar</h1>
          <h4>
            O sistema PolliPark é o melhor investimento de segurança e orientação de vagas baseado
            em câmeras. Diferente dos sistema com sensores, que apenas controlam a ocupação de
            vagas, o sistema de câmeras se torna uma ferramenta poderosa de segurança, que auxilia
            gestão do estacionamento e facilita a sua operação.
          </h4>
          <p>
            <img src={Check} alt='' style={{ maxWidth: 40 }} /> Maior sensação de segurança ao
            clientes e agilidade para estacionar
          </p>
          <p>
            <img src={Check} alt='' style={{ maxWidth: 40 }} />
            Gestão completa, controle de segurança e operacional do estacionamento
          </p>
          <p>
            <img src={Check} alt='' style={{ maxWidth: 40 }} /> Inibição de pessoas mal
            intencionadas
          </p>
          <p>
            <img src={Check} alt='' style={{ maxWidth: 40 }} /> Redução instantânea a praticamente
            ZERO de sinistros!
          </p>
        </div>
        <div className='right'>
          <img src={Camera} alt='' />
        </div>
      </ServiceMain>
    </>
  )
}
