import styled from "styled-components";

export const ContentMain = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  
  // @media (max-width: 1000px) {
  //   height: 65vh;
  // }

  div {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(71, 67, 68, 0.3);
    font-family: "Poppins";
    color: #ffffff;
    gap: 20px;

    

    p {
      text-align: center;
      width: 60%;
     
    }

    img {
      width: 100%;
      height: 100%;
      z-index: -2;

      @media (max-width: 620px) {
        height: 400px;
      }
    }

    .content-form {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 400px;

      @media (max-width: 620px) {
        height: 300px;
      }

      p{
        width: 90%;
      }

      h1{
        font-size: 26px;
        text-align: center;
      }
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    input {
      color: #ffffff;
      height: 50px;
      padding: 13px 23px;
      border-radius: 5px;
      border: none;
      background-color: rgba(0, 0, 0, 0.3);
      font-family: "Poppins";
      font-weight: 400;
      font-size: 18px;
    

      @media (max-width: 1368px) {
        
        width: 300px;
        font-size: 12px;
        height: 30px;
        padding: 0 23px;
        
      }

    

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #ffffff;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-decoration: none;
      color: #ffffff;
      background-color: #c91b1d;
      font-size: 18px;
      font-family: "Poppins";
      font-weight: 600;
      height: 50px;
      width: 270px;
      padding: 5px;
      border-radius: 5px;

      @media (max-width: 1368px) {
        width: 150px;
        font-size: 12px;
        height: 40px;
      }

      @media (max-width: 1000px) {
        width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        top: 0;
        left: 0;
        background-color: #474344;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        border-radius: 5px;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      p {
        font-family: "Poppins";
        font-weight: 700;
        z-index: 2;
      }
    }
  }
`;
