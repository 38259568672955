import styled from 'styled-components'

export const PhotoCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  height: 120vh;
  overflow: hidden;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
    text-align: center;
    font-family: 'Poppins';
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 1000px) {
      width: 90%;
    }

    p {
      font-size: 17px;
    }
  }

  .cards {
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    img {
      width: 400px;
      height: 400px;
      border-radius: 20px;

      @media (max-width: 1368px) {
        width: 300px;
        height: 300px;
      }
    }
  }
`

export const More = styled.a`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 10%;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: #ffffff;
    background-color: #c91b1d;
    font-size: 14px;
    height: 42px;
    width: 177px;
    padding: 5px;
    border-radius: 5px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scaleX(0);
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
      border-radius: 5px;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    p {
      font-family: 'Poppins';
      font-weight: 700;
      z-index: 2;
    }
  }
`
