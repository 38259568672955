import React from 'react'
import { Content, HeaderProducts } from './styles'
import Card from './Card'
import Logo from '../../../../assets/logopollipark.png'

export default function Video() {
  return (
    <Content>
      <HeaderProducts>
        <img src={Logo} alt='' style={{ width: '200px' }} />
        <p style={{ color: 'red' }}>
          VÍDEO-MONITORAMENTO
          <br /> <span style={{ color: '#000000' }}>DE VAGAS</span>
        </p>
      </HeaderProducts>
      <Card />
    </Content>
  )
}
