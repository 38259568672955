import styled from 'styled-components'

export const Cards = styled.div`
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Poppins';
  margin-bottom: 20px;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 400px;
    gap: 20px;
    height: 550px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border-bottom: 4px solid black;
    cursor: pointer;

    @media (max-width: 1440px) {
      width: 280px;
      height: 400px;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 20px;
      gap: 20px;
    }

    h1 {
      font-size: 18px;
      color: red;
      height: 40px;

      @media (max-width: 1440px) {
        font-size: 15px;
      }
    }

    p {
      font-size: 15px;
      text-align: center;

      @media (max-width: 1440px) {
        font-size: 12px;
      }
    }

    &:hover {
      border-bottom: 4px solid #c91b1d;
      transform: scale(1.01);
      transition: all ease-in-out 400ms;
    }

    img {
      width: 100%;
      height: 250px;
      border-radius: 8px 8px 0px 0px;

      @media (max-width: 1440px) {
        height: 180px;
      }
    }
  }
`
