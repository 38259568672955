import React from 'react'
import { Link } from 'react-router-dom'

import { Content } from './styles'

export default function AboutUs() {
  return (
    <Content>
      <div className='left'>
        <h3>SOBRE NÓS</h3>
        <h1>A POLLIPARK</h1>
        <p className='description'>
          A PolliPark surgiu com intuito de suprir a demanda do mercado nacional sinalização de
          vagas livres e ocupadas baseada em vídeo-monitoramento, tudo em um único produto de alto
          padrão para estacionamentos.
        </p>

        <p className='description'>
          Cumprindo com altos padrões de qualidade, a PolliPark trabalha como responsável pela
          instalação e manutenção dos sistema guiado por câmeras no Brasil.
        </p>

        <p className='description'>
          A POLLIPARK além de possuir tecnologia própria e com parceiros, segue desenvolvendo novos
          produtos para atender nichos existentes no mercado, visando oferecer soluções com um
          retorno de investimento (ROI) muito rápido aos clientes.
        </p>

        <div className="btn-about">
          <Link to='/a-pollipark' className='btn'>
            <p className='about'>SAIBA MAIS</p>
          </Link>
        </div>
      </div>
      <div className='right'>
        <iframe
          width='753'
          height='380'
          style={{ borderRadius: '10px' }}
          src='https://www.youtube.com/embed/I8I_b05UMwQ'
          allowFullScreen
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          frameBorder='0'
          title="description"
        ></iframe>
      </div>
    </Content>
  )
}
