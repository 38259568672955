import React from 'react'
import Medal from '../../../assets/cards/medal.png'
import Cctv from '../../../assets/cards/cctv.png'
import Disability from '../../../assets/cards/disability.png'
import Hours from '../../../assets/cards/24-hours.png'
import { Content } from './styles'

export default function Functionality() {
  return (
    <Content>
      <div className='border-l border-c'>
        <img src={Disability} alt='' />
        <p>SISTEMA DE ORIENTAÇÃO COM LUZ EM LED MULTICOR E SINALIZAÇÃO COM SÍMBOLOS ESPECIAIS</p>
      </div>
      <div className='border-c'>
        <img src={Hours} alt='' />
        <p>24 HORAS 7 DIAS POR SEMANA DE GRAVAÇÃO DE VÍDEO PARA CADA VAGA DE ESTACIONAMENTO</p>
      </div>
      <div className='border-c'>
        <img src={Cctv} alt='' />
        <p>CÂMERAS COM A MELHOR QUALIDADE DE IMAGEM DO MERCADO</p>
      </div>
      <div className='border'>
        <img src={Medal} alt='' />
        <p>SUITE DE GERENCIAMENTO COM RELATÓRIOS, GRAFICOS, EXPORTAÇÕES, INTEGRAÇÕES etc</p>
      </div>
    </Content>
  )
}
