import styled from 'styled-components'
import arrowL from '../../../assets/slider/arrowleft.svg'
import arrowR from '../../../assets/slider/arrowright.svg'

export const SliderMain = styled.div`
  width: 100%;
  overflow: hidden;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 1500px;
  }

  .swiper {
    width: 100%;
    height: 100%;


  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    transition: all 300ms ease-out;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .swiper-button-prev {
    background-image: url(${arrowL});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    left: 2%;
  }
  .swiper-button-next {
    background-image: url(${arrowR});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    right: 2%;
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    transform: scale(1.1);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
`
