import React, { useState } from "react";
import { ContentMain } from "./styles";
// import { toast } from 'react-toastify'
import emailjs from "@emailjs/browser";
import bannerContact from '../../../assets/banner-contact.png'

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tele, setTele] = useState("");

  function sendForm(e: { preventDefault: () => void }) {
    e.preventDefault();
    if (name === "" || email === "" || tele === "") {
      alert("Preencha todos os campos");
      return;
    }

    const templateParams = {
      from_name: name,
      message: tele,
      email: email,
    };
    emailjs
      .send(
        "service_1i5at46",
        "template_s76u6ae",
        templateParams,
        "pndTBNjFLdAyJwJwC"
      )
      .then(
        (response) => {
          console.log("email send", response.status, response.text);
          setName("");
          setEmail("");
          setTele("");
        },
        (err) => {
          console.log(err);
        }
      );
  }
  return (
    <ContentMain>
      <div>
        <img src={bannerContact} alt="" />
        <div className="content-form">
          <h1>Quer conhecer mais sobre nós?</h1>
          <p>
            Atendemos todos empreendimentos com estacionamentos cobertos acima de
            500 vagas. Deixe abaixo o seu interesse que iremos entrar em contato:
          </p>
          {/* <form onSubmit={sendForm}>
            <input
              type='text'
              name='remetente'
              placeholder='Nome do remetente'
              onChange={(e) => {
                setName(e.target.value)
              }}
              value={name}
            />
            <br />
            <input
              type='text'
              name='destinatario'
              placeholder='Nome do destinatário'
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              value={email}
            />
            <br />
            <input
              type='text'
              name='destinatarioemail'
              placeholder='E-mail do destinátario'
              onChange={(e) => {
                setTele(e.target.value)
              }}
              value={tele}
            />
            <input type='submit' value='Enviar Mensagem' />
          </form> */}
          <form onSubmit={sendForm}>
            <input
              type="text"
              placeholder="Nome *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="E-mail *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="tel"
              placeholder="Telefone *"
              onChange={(e) => setTele(e.target.value)}
              value={tele}
            />
            <input className="btn" type="submit" value="Enviar" />
          </form>
        </div>
      </div>
    </ContentMain>
  );
}
