import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #f6f6f6;
  font-family: 'Poppins';
  gap: 100px;
  align-items: center;
  padding: 30px 0 35px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1000px) {
      width: 40%;
    }

    h1 {
      font-size: 60px;

      @media (max-width: 1368px) {
        font-size: 30px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 700;
      text-align: center;

      @media (max-width: 1368px) {
        font-size: 15px;
        font-weight: 700;
        text-align: center;
      }
    }
    img {
      width: 100px;
    }
  }
`
