import styled from "styled-components";
// import BG from '../../assets/banner-galery.jpeg'

export const Header = styled.header`
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(71, 67, 68, 0.8);
    font-family: "Poppins";
    color: #ffffff;
    height: 300px;

    @media (max-width: 768px) {
      height: 200px;
    }

    h1 {
      position: absolute;
      font-size: 40px;
    }

    img {
      width: 100%;
      height: 300px;
      z-index: -2;

      @media (max-width: 768px) {
        height: 200px;
      }
    }
  }
`;
export const PhotoCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  margin-top: 100px;

  .cards {
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

    img {
      width: 400px;
      height: 400px;
      border-radius: 10px;

      @media (max-width: 1368px) {
        width: 300px;
        height: 300px;
      }
    }
  }
`;
