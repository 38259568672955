import React from 'react'
import { Header } from './styles'
import Description from './Description'
import Prices from './Prices'
import Monitor from './Monitor'
import bannerPollipark from '../../assets/banner-pollipark.jpeg'

export default function Pollipark() {
  return (
    <>
      <Header>
        <div>
          <img src={bannerPollipark} alt='cameras'/>
          <h1>
            <p>A Pollipark</p>
          </h1>
        </div>
      </Header>
      <Description />
      <Prices />
      <Monitor />
    </>
  )
}
