import React from 'react'
import { useCookies } from 'react-cookie'
import { CookieBanner } from './style'

const Cookies = () => {
  const [cookies, setCookies] = useCookies(['cookieConsent'])

  const acceptCookies = () => {
    setCookies('cookieConsent', 'accepted', { path: '/', maxAge: 60 * 60 * 24 * 365 })
  }

  const declineCookies = () => {
    setCookies('cookieConsent', 'declined', { path: '/' })
  }

  // Exibir o banner apenas se o consentimento não estiver definido
  if (cookies.cookieConsent) {
    return null // Não renderiza nada se o consentimento já foi dado ou recusado
  }

  return (
    <CookieBanner>
      <div className='container-cookies'>
        <h3>Política de Privacidade</h3>
        <p>
          Usamos cookies para melhorar a navegação no site. Para mais informações, veja nossa{' '}
          <a href='/privacidade'>Política de Privacidade</a>.
        </p>
      </div>
      <div className='cookies-btns'>
        <button onClick={acceptCookies} className='btn-accept'>
          Aceitar
        </button>
        <button onClick={declineCookies} className='btn-decline'>
          Recusar
        </button>
      </div>
    </CookieBanner>
  )
}

export default Cookies
