import styled from 'styled-components'

export const FooterMainHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 40vh;
  background-color: #c91b1d;
  font-family: 'Poppins';
  color: #ffffff;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    height: 70vh;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    height: 350px;
    border-right: 1px solid black;
    padding: 0 30px;
    text-align: center;
    justify-content: center;
    gap: 30px;

    p{
      padding: 10px 30px;
    }

    @media (max-width: 1000px) {
      width: 100%;
    }

    img {
      width: 200px;
    }
  }

  .right {
    max-width: 500px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: 1000px) {
      width: 100%;
    }

    .email {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-weight: 700;
        font-size: 18px;
      }

      a {
        color: #ffffff;
      }

    
    }

    .social {
      display: flex;
      font-size: 30px;
      gap: 20px;

      a {
        &:hover {
          transition: all ease-in-out 400ms;
          transform: scale(1.2);
        }
      }
    }
  }
`

export const EndFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #474344;
  height: 50px;
  font-family: 'Poppins';
  font-size: 12px;
  color: #ffffff;
  align-items: center;
`
