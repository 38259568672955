import { Header, PhotoCard } from "./styles";
import { imgs } from "../../data";
import bannerGalery from "../../assets/banner-galery.jpeg";

export default function Photos() {
  return (
    <>
      <Header>
        <div>
          <img src={bannerGalery} />
          <h1>Galeria</h1>
        </div>
      </Header>
      <PhotoCard>
        <div className="cards">
          {imgs.map((item) => (
            <div key={item.id}>
              <img src={item.image} alt="imagens pollipark" />
            </div>
          ))}
        </div>
      </PhotoCard>
    </>
  );
}
