import React from 'react'
import Light from '../../../../../assets/cards/qualidade.jpg'
import Clpr from '../../../../../assets/cards/gravacao247.jpg'
import Inspec from '../../../../../assets/cards/dashboard2.jpg'
import { Cards } from './styles'

export default function Card() {
  return (
    <Cards>
      <div className='content'>
        <img src={Light} alt='' />
        <div>
          <h1>
            Melhor qualidade <br />
            de imagem de câmera
          </h1>
          <p>
            Nós temos a melhor imagem de câmera para vídeomonitoramento do mercado. Não perca nenhum
            detalhe na verificação de sinistros e alertas no estacionamento.
          </p>
        </div>
      </div>
      <div className='content'>
        <img src={Clpr} alt='' />

        <div>
          <h1>Gravação 24/7</h1>
          <p>
            Você terá acesso à todas gravações 24 horas por dia, 7 dias por semana ao dispor do
            estacionamento, garantindo maior segurança e capacidade de gestão de risco com os carros
            estacionados.
          </p>
        </div>
      </div>
      <div className='content'>
        <img src={Inspec} alt='' />
        <div>
          <h1>Monitoramento em tempo real</h1>
          <p>
            Único sistema com monitoramento em tempo real para acompahamento de qualquer necessidade
            do seu estacionamento. Tenha acesso com exatidão à câmera em qualquer hora do dia para o
            seu estacionamento.
          </p>
        </div>
      </div>
    </Cards>
  )
}
