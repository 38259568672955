import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules'
import { SliderMain } from './styles'

import SwiperOne from '../../../assets/slider/swiper.jpg'
import SwiperTwo from '../../../assets/slider/slider2novo.jpg'
import SwiperThree from '../../../assets/slider/swiper3.jpg'

import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Link } from 'react-router-dom'

export default function SliderContent() {
  return (
    <SliderMain>
      <Swiper
        // loop
        // autoplay={{
        //   delay: 3500,
        //   disableOnInteraction: false,
        // }}
        // centeredSlides
        initialSlide={0}
        pagination={{
          clickable: true,
        }}
        spaceBetween={70}
        slidesPerView={1}
        navigation
        modules={[Navigation, Autoplay]}
        // onSwiper={(swiper) => console.log(swiper)}
        className='mySwiper'
      >
        <SwiperSlide>
          <div className='swiper-desciption'>
            <h2>O mais eficiente sistema de</h2>
            <h1>MONITORAMENTO DE VAGAS DO MERCADO</h1>
            <p>Mais de 2 milhões de veículos monitorados por dia no mundo</p>
            <Link to='/solicitar' className='btn'>
              <p style={{ fontSize: 20 }}>SOLICITAR PROPOSTA</p>
            </Link>
          </div>
          <img src={SwiperOne} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-desciption'>
            <h2>Tome decisões mais eficientes com o</h2>
            <h1>MONITORAMENTO DE VAGAS DO MERCADO</h1>
            <p>da PolliPark</p>
            <Link to='/solicitar' className='btn'>
              <p style={{ fontSize: 20 }}>SOLICITAR PROPOSTA</p>
            </Link>
          </div>

        
          <img src={SwiperTwo} alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-desciption'>
            <h2>Único sistema com</h2>
            <h1>MONITORAMENTO POR VÍDEO EM TEMPO REAL</h1>
            <p>Garanta a segurança do seu estacionamento</p>
            <Link to='/solicitar' className='btn'>
              <p style={{ fontSize: 20 }}>SOLICITAR PROPOSTA</p>
            </Link>
          </div>
          <img src={SwiperThree} alt='' />
        </SwiperSlide>
      </Swiper>
    </SliderMain>
  )
}
