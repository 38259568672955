import styled from 'styled-components'

export const List = styled.ul`
  display: flex;
  gap: 50px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 1000px) {
    display: none;
  }

  a {
    position: relative;
    text-decoration: none;
    color: #000000;

    list-style: none;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #c91b1d;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`
