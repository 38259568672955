import React from 'react'
import { Content } from '../../home/AboutUs/styles'

export default function Description() {
  return (
    <>
      <Content>
        <div className='left'>
          <h3>SOBRE NÓS</h3>
          <h1>A POLLIPARK</h1>
          <p className='description'>
            A PolliPark surgiu com intuito de suprir a demanda do mercado nacional sinalização de
            vagas livres e ocupadas baseada em vídeo-monitoramento, tudo em um único produto de alto
            padrão para estacionamentos.
          </p>

          <p className='description'>
            Cumprindo com altos padrões de qualidade, a PolliPark trabalha como sua parceira e é a
            responsável pela Instalação e Manutenção da ParkEyes no Brasil.
          </p>

          <p className='description'>
            A POLLIPARK além de possuir tecnologia própria e com parceiros, segue desenvolvendo
            novos produtos para atender nichos existentes no mercado, visando oferecer soluções com
            um retorno de investimento (ROI) muito rápido aos clientes.
          </p>
        </div>
        <div className='right'>
          <iframe
            width='753'
            height='380'
            style={{ borderRadius: '20px' }}
            src='https://www.youtube.com/embed/I8I_b05UMwQ'
            allowFullScreen
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            frameBorder='0'
            title="description"
          ></iframe>
        </div>
      </Content>
    </>
  )
}
