import styled from 'styled-components'

export const Main = styled.div`
  img {
    width: 100%;
  }
`

export const Content = styled.div`
  background-color: red;
  padding: 20px 40px;

  @media (max-width: 1000px) {
    padding: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20%;
    font-family: 'Poppins';
    color: #ffffff;
    gap:20px;

    p {
      font-size: 18px;
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: 15px;
    gap: 30px;

    &::after {
      content: ' ';
      position: absolute;
      width: 6px;
      height: 100%;
      background-color: #ffffff;
      top: 0;
      left: 50.65%;
      margin-left: -3px;
      z-index: 1;
      border-radius: 10px;

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .otimize {
    position: relative;
    width: 50%;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    left: 0;
    text-align: end;
    color: white;
    font-family: 'Poppins';

    @media (max-width: 1000px) {
      width: 100%;
      right: 0;
      left: 2%;
      text-align: start;
      align-items: flex-start;
    }

    &:hover > .font {
      color: white;
    }
    .font {
      left: 0;

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .right {
    margin: 0 25px;
    left: 50%;
    color: white;
    font-family: 'Poppins';

    @media (max-width: 1000px) {
      width: 100%;
      margin: 0;
      left: 2%;
    }

    &:hover > .font {
      color: white;
    }
    .font {
      left: -25px;

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .font {
    position: absolute;
    right: -20px;
    top: 32px;
    z-index: 10;
    color: black;
    font-size: 20px;
  }
`
