import React from "react";
import { HelpMain } from "./styles";
import Left from "./Left";
import BannerForm from "../../../assets/banner-form.png";

export default function Help() {
  return (
    <HelpMain>
      <div className="filter">
        <img src={BannerForm} alt="" />
      </div>
      <Left />
    </HelpMain>
  );
}
