import styled from 'styled-components'
import Somos from '../../../assets/muitomais2.jpg'

export const Content = styled.div`
  background-image: url(${Somos});

  background-repeat: no-repeat;
  background-size: cover;

  .bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  font-family: 'Poppins';
  text-align: center;
  color: #ffffff;
  gap: 50px;
  padding: 40px 10px;

  .monitor {
    font-size: 20px;

    :nth-child(1) {
      font-size: 40px;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: #ffffff;
    background-color: #c91b1d;
    font-size: 14px;
    height: 70px;
    width: 300px;
    padding: 5px;
    border-radius: 5px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scaleX(0);
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
      border-radius: 5px;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    p {
      font-family: 'Poppins';
      font-weight: 700;
      z-index: 2;
    }
  }
`
