import styled from "styled-components";

export const HelpMain = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 500px;
  background-color: rgba(71, 67, 68, 0.8);
  margin-top: 50px;
  gap: 40px;
  text-align: center;

  @media (max-width: 768px) {
    height: 650px;
  }

  .filter {
    width: 100%;
    height: 100%;
  }

  img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  .left {
    position: absolute;
    color: #ffffff;
    z-index: 2;
    top: 10px;
    width: 70%;
    height: 100%;
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;

    h1 {
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      text-align: center;
      width: 70%;
           
      @media (max-width: 768px) {
        font-size: 14px;
      }
     
    }

    @media (max-width: 1000px) {
      width: 100%;
    }

    

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0 auto;
    }

    .form-row {
      display: flex;
      gap: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .inputs-form,
      .select-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      input {
        width: 400px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #ffffff;
        padding: 10px 15px;
        color: #ffffff;
        font-family: "Poppins";
        font-size: 14px;

        input::after {
          background-color: transparent;
        }

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #ffffff;
          font-family: "Poppins";
        }

        @media (max-width: 768px) {
          width: 300px;
          font-size: 12px;
        }
      }

      select {
        width: 400px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #ffffff;
        padding: 10px 15px;
        font-size: 14px;
        color: #ffffff;
        font-family: "Poppins";

        option {
          color: #000000;
          font-family: "Poppins";
          font-size: 14px;
        }

        @media (max-width: 768px) {
          width: 300px;
          font-size: 12px;
        }
      }
    }

    .send {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      justify-content: center;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-decoration: none;
        color: #ffffff;
        background-color: #c91b1d;
        font-size: 14px;
        height: 42px;
        width: 177px;
        padding: 5px;
        border-radius: 5px;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          transform: scaleX(0);
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.9);
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          border-radius: 5px;
        }

        &:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }

        p {
          font-family: "Poppins";
          font-weight: 700;
          z-index: 2;
        }
      }
    }
  }

  .right {
    z-index: 1;

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;
