import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  width: 100%;
 
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  div {
    position: relative;
    width: 300px;
    height: 90%;
    text-align: center;
    padding: 25px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    text-transform: uppercase;
  }

  .border {
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    @media (max-width: 1000px) {
      border-right: none;
    }
  }

  .border-l {
    border-left: 1px solid rgba(0, 0, 0, 0.1);

    @media (max-width: 1000px) {
      border: none;
    }
  }

  .border-c {
    &::after {
      content: '';
      right: 0;
      top: 50%;
      height: 50px;
      width: 1px;
      background: #ddd;
      position: absolute;
      margin-top: -25px;

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
`
