import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  justify-content: center;
  gap: 60px;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .description {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 30px;
    text-align: justify;

    @media (max-width: 1368px) {
      font-size: 12px;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 20px;
    padding: 40px;
    @media (max-width: 1000px) {
      width: 100%;
    }

    h1 {
      font-weight: 700;
      font-size: 30px;
      color: #474344;
    }

    h4 {
      font-weight: 400;
      font-size: 18px;
      color: #777;
      text-align: justify;
    }
    .description {
      color: #666666;
      font-weight: 800;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
    }


    .btn-about {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-decoration: none;
      color: #ffffff;
      background-color: #c91b1d;
      font-size: 14px;
      height: 42px;
      width: 177px;
      padding: 5px;
      border-radius: 5px;


      p {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 1000px) {
        display: none;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.9);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        border-radius: 5px;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      .about {
        font-family: 'Poppins';
        font-weight: 700;
        z-index: 2;
      }
    }
  }

  .right {
    display: flex;
    justify-content: center;
    width: 40%;
    margin-bottom: 40px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }
`
