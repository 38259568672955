import styled from "styled-components";
import arrowL from "../../../assets/slider/arrowleft.svg";
import arrowR from "../../../assets/slider/arrowright.svg";

export const SliderMain = styled.div`
  width: 100%;
  overflow: hidden;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    height: 100vh;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      filter: blur(5px);
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-decoration: none;
      color: #ffffff;
      background-color: #c91b1d;
      font-size: 14px;
      height: 50px;
      width: 300px;
      padding: 5px;
      border-radius: 5px;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.9);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        border-radius: 5px;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      p {
        font-family: "Poppins";
        font-weight: 700;
        z-index: 2;
      }
    }

    .swiper-desciption {
      display: flex;
      gap: 40px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      flex-direction: column;
      padding: 0 45px;
      position: absolute;
      top: 0;
      z-index: 10;
      color: #ffffff;
      font-family: "Poppins";

      h2 {
        font-size: 30px;
        font-weight: 400;
        transition: all ease-in-out 1000ms;

        @media (max-width: 620px) {
          font-size: 28px;
        }
      }

      h1 {
        font-size: 40px;
        transition: all ease-in-out 1000ms;

        @media (max-width: 1000px) {
          font-size: 30px;
        }
      }

      p {
        font-size: 30px;
        transition: all ease-in-out 1000ms;

        @media (max-width: 620px) {
          font-size: 28px;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    transition: all 300ms ease-out;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .swiper-button-prev {
    background-image: url(${arrowL});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    left: 2%;
  }
  .swiper-button-next {
    background-image: url(${arrowR});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    right: 2%;
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    transform: scale(1.1);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
`;
