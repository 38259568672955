import styled from "styled-components";

export const Header = styled.header`
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(71, 67, 68, 0.8);
    font-family: "Poppins";
    color: #ffffff;
    height: 300px;

    @media (max-width: 768px) {
      height: 200px;
    }

    h1 {
      position: absolute;
      font-size: 40px;

      @media (max-width: 600px) {
        font-size: 28px;
      }
    }

    img {
      width: 100%;
      z-index: -2;
      height: 300px;

      @media (max-width: 768px) {
        height: 200px;
      }
    }
  }
`;
