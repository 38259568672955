import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import GlobalStyles from './styles/GlobalStyles'
import HeaderContent from './components/HeaderMain'
import Footer from './components/Footer'
import { ToastContainer } from 'react-toastify'
import { CookiesProvider } from 'react-cookie'

import Cookies from './components/Cookies/Cookies'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <CookiesProvider>
    <BrowserRouter>
      <HeaderContent />
      <Routes />
      <ToastContainer autoClose={1500} className='toast-container' />
      <GlobalStyles />
      <Cookies/>
      <Footer />
    </BrowserRouter>
  </CookiesProvider>,
)
