import React from "react";
import { toast } from "react-toastify";

export default function Left() {
  const send = () => {
    toast.success("Enviado com sucesso.", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setInterval(() => {
      window.location.replace("/");
    }, 2500);
  };
  return (
    <>
      <div className="left">
        <h1>Solicite uma Proposta para Nossa Equipe</h1>
        <p>
          Preencha os campos abaixo que enviaremos uma análise gratuita de como
          o sistema da PolliPark pode acrescentar qualidade e segurança para o
          seu estacionamento:
        </p>

        <form>
          <div className="form-row">
            <div className="inputs-form">
              <input type="text" placeholder="Nome" autoComplete="off" />
              <input type="text" placeholder="Empresa" autoComplete="off" />
              <input type="email" placeholder="Email" autoComplete="off" />
            </div>
            <div className="select-form">
              <select defaultValue={"placeholder"}>
                <option disabled value={"placeholder"}>
                  Escolha seu nicho
                </option>
                <option value="">Shopping</option>
                <option value="">Estacionamento aberto</option>
                <option value="">Estacionamento coberto</option>
                <option value="">Estabelecimento comercial</option>
                <option value="">Outros</option>
              </select>
              <select defaultValue={"placeholder"}>
                <option disabled value={"placeholder"}>
                  Selecione seu cargo
                </option>
                <option value="">Sócio-proprietário</option>
                <option value="">Gerente</option>
                <option value="">Funcionário</option>
              </select>
              <select defaultValue={"placeholder"}>
                <option disabled value={"placeholder"}>
                  Numeros de vagas
                </option>
                <option value="">Até 500</option>
                <option value="">500 - 700</option>
                <option value="">+ 700</option>
              </select>
            </div>
          </div>
          <div className="send">
            <a href="#" className="btn" onClick={() => send()}>
              <p> ENVIAR</p>
            </a>
          </div>
        </form>
      </div>
    </>
  );
}
