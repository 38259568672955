import one from '../assets/fotos/1.jpg'
import one2 from '../assets/fotos/2.jpg'
import one3 from '../assets/fotos/3.jpg'
import one4 from '../assets/fotos/4.jpg'
import one5 from '../assets/fotos/5.jpg'
import one6 from '../assets/fotos/6.jpg'
import one7 from '../assets/fotos/7.jpg'
import one8 from '../assets/fotos/8.jpg'
import one9 from '../assets/fotos/9.jpg'
import one10 from '../assets/fotos/10.jpg'
import one11 from '../assets/fotos/11.jpg'
import one12 from '../assets/fotos/12.jpg'


export const imgs = [
  {
    id: 1,
    image: one,
  },
  {
    id: 2,
    image: one2,
  },
  {
    id: 3,
    image: one3,
  },
  {
    id: 4,
    image: one4,
  },
  {
    id: 5,
    image: one5,
  },
  {
    id: 6,
    image: one6,
  },
  {
    id: 7,
    image: one7,
  },
  {
    id: 8,
    image: one8,
  },
  {
    id: 9,
    image: one9,
  },
  {
    id: 10,
    image: one10,
  },
  {
    id: 11,
    image: one11,
  },
  {
    id: 12,
    image: one12,
  }
]
