import React from 'react'
import { Content, HeaderProducts } from './styles'
import Logo from '../../../../assets/logopollipark.png'
import Card from './Card'

export default function Controller() {
  return (
    <Content>
      <HeaderProducts>
        <img src={Logo} alt='' style={{ width: '200px' }} />
        <p style={{ color: 'red' }}>
          CONTROLE E GESTÃO
          <br /> <span style={{ color: '#000000' }}>EM TEMPO REAL</span>
        </p>
      </HeaderProducts>
      <Card />
    </Content>
  )
}
