import styled from 'styled-components'

export const ServiceMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  background-color: #f6f6f6;

  img {
    width: 400px;

    @media (max-width: 1440px) {
      width: 300px;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;
    padding: 40px;

    @media (max-width: 1000px) {
      width: 100%;
    }

    h1 {
      font-weight: 700;
      font-size: 30px;
      color: #474344;
      @media (max-width: 1000px) {
        font-size: 20px;
      }
    }

    h4 {
      font-weight: 400;
      font-size: 16px;
      color: #777;
      text-align: justify;
    }
    p {
      color: #666666;
      font-weight: 800;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    width: 30%;
    align-items: center;

    @media (max-width: 1000px) {
      display: none;
    }
  }
`
