import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins';
  gap: 20px;
`

export const HeaderProducts = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins';
  font-size: 20px;
  text-align: center;
  font-weight: 800;
`
