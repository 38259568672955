import React from 'react'

import { imgs } from '../../../data'
import { More, PhotoCard } from './styles'
import { Link } from 'react-router-dom'

export default function Photos() {
  return (
    <PhotoCard>
      <div className='header'>
        <h1>Cases e Clientes</h1>
        <p>
          Confira aqui o sistema instalado em diversos estacionamentos de nossos principais clientes
          e cases de sucesso no Brasil.
        </p>
      </div>
      <div className='cards'>
        {imgs.map((item) => (
          <div key={item.id}>
            <img src={item.image} alt='' />
          </div>
        ))}
      </div>
      <More>
        <Link to='/galeria' className='btn'>
          <p>Ver Mais</p>
        </Link>
      </More>
    </PhotoCard>
  )
}
