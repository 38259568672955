import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/home'
import Solution from '../pages/Solution'
import Pollipark from '../pages/Pollipark'
import Photos from '../pages/Galeria'
import { useLocation } from 'react-router-dom'
import Privacy from '../pages/Privacy'
import Budget from '../pages/Budget'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    })
  }, [pathname])

  return null
}
function Router() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='//a-pollipark' element={<Pollipark />} />
        <Route path='/solucao' element={<Solution />} />
        <Route path='/galeria' element={<Photos />} />
        <Route path='/privacidade' element={<Privacy />} />
        <Route path='/solicitar' element={<Budget />} />
      </Routes>
    </>
  )
}
export default Router
