import React from 'react'
import Light from '../../../../../assets/cards/ocupacao.jpg'
import Clpr from '../../../../../assets/cards/tudo2.jpg'
import Inspec from '../../../../../assets/cards/qualvaga.jpg'
import { Cards } from './styles'

export default function Card() {
  return (
    <Cards>
      <div className='content'>
        <img src={Light} alt='' />
        <div>
          <h1>
            Acompanhe o percentual de <br />
            ocupação
          </h1>
          <p>
            Entenda como otimizar o uso das vagas sabendo o percentual de ocupação de cada dia,
            facilitando em análise de projeções e entendimento das necessidades de crescimento do
            seu estacionamento.
          </p>
        </div>
      </div>
      <div className='content'>
        <img src={Inspec} alt='' />
        <div>
          <h1>Saiba qual vaga está ocupada</h1>
          <p>
            Para garantir a segurança para o seu cliente, você sabe exatamente qual vaga está
            ocupada e qual não está, entendendo como mediar furtos, conflitos e outros tipos de
            problemas com o seu estacionamento. Aliás, com a solução ParkEyes, você reduz a ZERO os
            sinistros do seu estacionamento!
          </p>
        </div>
      </div>
      <div className='content'>
        <img src={Clpr} alt='' />
        <div>
          <h1>Controle tudo sobre o seu estacionamento</h1>
          <p>
            Tenha em mãos um software de gestão completo de vagas para o seu estacionamento e
            monitore e acompanhe em tempo real o que acontece no estacionamento, identificando áreas
            com alta ou baixa rotatividade para ações rentáveis tornando seu espaço mais rentável.
          </p>
        </div>
      </div>
    </Cards>
  )
}
