import React from 'react';
import Image from '../../assets/test.jpg'


import { Header } from './styles'

import Flags from '../home/Flags'

import Services from './Services'
import SliderContent from './Swiper'

export default function Solution() {
  return (
    <>
      <Header>
        <div>
          <img src={Image} alt="" />
          <h1>Nossa Solução</h1>
        </div>
      </Header>
      <Services />
      <Flags />
      <SliderContent />
    </>
  )
}
