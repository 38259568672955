import React from 'react'
import { Content, Description } from './styles'
import { Link } from 'react-router-dom'

export default function Monitor() {
  return (
    <Content>
      <div className='bg'>
        <Description>
          <h3>MUITO MAIS QUE UM SENSOR PARA ESTACIONAMENTO</h3>
          <h1>UM SISTEMA COMPLETO DE GESTÃO E VIDEOMONITORAMENTO DE VAGAS PARA VOCÊ</h1>
          <div className='monitor'>
            <p>+ 80.000</p>
            <p>Vagas sinalizadas todos os dias</p>
          </div>

          <Link to='/solicitar' className='btn'>
            <p>MODERNIZE SEU ESTACIONAMENTO</p>
          </Link>
        </Description>
      </div>
    </Content>
  )
}
