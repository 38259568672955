import styled, { keyframes, css } from 'styled-components'

export const Mobile = styled.header`
  display: none;
  position: relative;

  @media (max-width: 1000px) {
    display: flex;
  }

  button {
    z-index: 10;
    border: none;
    background-color: transparent;
    font-size: 30px;
  }
`

const fadeIn = keyframes`
  from {
    transform: translateY(-100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100vh);
    opacity: 0;
  }
`

export const MobileShow = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 15px;
  justify-content: center;
  width: 100%;
  height: 60vh;
  z-index: 2;
  top: 0;
  background-color: #ffffff;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${fadeIn} 1s
        `
      : css`
          ${fadeOut} 1s
        `};

  a {
    color: black;
  }

  .linka {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: #ffffff;
    background-color: #c91b1d;
    font-size: 14px;
    height: 42px;
    width: 177px;
    padding: 5px;
    border-radius: 5px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scaleX(0);
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
      border-radius: 5px;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    p {
      font-family: 'Poppins';
      font-weight: 700;
      z-index: 2;
    }
  }
`
