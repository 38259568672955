import React from 'react'
import Form from './Form'
import { Header, Main } from './styles'

export default function Budget() {
  return (
    <>
      <Header>
        <div>
          <h1>
            <p>A Polipark</p>
          </h1>
        </div>
      </Header>
      <Main>
        <h1> Solicite uma proposta</h1>
      </Main>
      <Form />
    </>
  )
}
