import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Sinalization from './Sinalization'
import Video from './Video'
import Controller from './Controllers'
import { SliderMain } from './styles'

export default function SliderContent() {
  return (
    <SliderMain>
      <Swiper
        centeredSlides
        initialSlide={0}
        autoplay={{
          delay: 1111500,
          disableOnInteraction: false,
        }}
        spaceBetween={70}
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        // onSwiper={(swiper) => console.log(swiper)}
        className='mySwiper'
      >
        <SwiperSlide>
          <Sinalization />
        </SwiperSlide>
        <SwiperSlide>
          <Video />
        </SwiperSlide>
        <SwiperSlide>
          <Controller />
        </SwiperSlide>
      </Swiper>
    </SliderMain>
  )
}
