import React from 'react'
import { Header } from './styles'
import Logo from '../../assets/logopollipark.png'
import Selects from './SelectMenu'
import { Link } from 'react-router-dom'
import HeaderMobile from './HeaderMobile'

function HeaderContent() {
  return (
    <Header>
      <Link to='/'>
        <img src={Logo} alt='' />
      </Link>
      <Selects />
      <Link to='/solicitar' className='btn'>
        <p>SOLICITAR PROPOSTA</p>
      </Link>

      <HeaderMobile />
    </Header>
  )
}

export default HeaderContent
