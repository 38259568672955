import styled from 'styled-components'

export const Header = styled.header`
  user-select: none;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 105px;

  img {
    width: 250px;
    height: 64px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: #ffffff;
    background-color: #c91b1d;
    font-size: 14px;
    height: 42px;
    width: 177px;
    padding: 5px;
    border-radius: 5px;

    @media (max-width: 1000px) {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scaleX(0);
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
      border-radius: 5px;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    p {
      font-family: 'Poppins';
      font-weight: 700;
      z-index: 2;
    }
  }
`
