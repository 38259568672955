import styled from "styled-components";

export const CookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  color: #333;
  padding: 10px;
  border-top: 1px solid #ccc;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    gap: 10px;
    width: 100vw;
  }

  .container-cookies {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  h3 {

    font-size: 30px;
    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  a {
    color: black;
    font-weight: 600;
    font-size: 16px;
  }

  .cookies-btns {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  .btn-accept {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: #ffffff;
    background-color: #c91b1d;
    font-size: 15px;
    font-weight: 700;
    height: 42px;
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    opacity: 1;

    @media (max-width: 600px) {
      width: 100px;
      font-size: 14px;
    }
  }

  .btn-accept:hover {
    opacity: 0.9;
  }

  .btn-decline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: #000;
    background-color: #969696;
    font-size: 15px;
    font-weight: 700;
    height: 42px;
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    opacity: 1;

    @media (max-width: 600px) {
      width: 100px;
      font-size: 14px;
    }
  }

  .btn-decline:hover {
    opacity: 0.9;
  }
`;
