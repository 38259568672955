import styled from "styled-components";

export const PrivacyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16;
  width: 100%;
  line-height: 1.6rem;
  font-family: "Poppins";
  text-align: justify;
  margin-top: 50px;

  p {
    padding: 10px 0;
  }

  h3 {
    padding: 30px 0;
    color: #c91b1d;
    font-size: 20px;
    text-align: center;
  }

  h4 {
    padding: 10px 0;
    font-weight: bold;
  }

  ol {
    color: #666666;

    li {
      list-style: outside;
      padding: 10px 0;
    }
  }

  ul {
    color: #666666;

    li {
      list-style: outside;
      padding: 10px 0;
    }

    span {
      font-weight: bold;
    }
  }

  div {
    width: 50%;

    @media (max-width: 1000px) {
      width: 90%;
    }

    p {
      color: #666666;
    }
  }
`;
