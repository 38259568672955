import React from "react";
import { FormContent } from "./styles";
import { toast } from "react-toastify";
import { useState } from "react";

export default function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");

  const send = () => {
    toast.success("Enviado com sucesso.", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setInterval(() => {
      window.location.replace("/");
    }, 2500);
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário
    alert("teste");
    send();
  };

  return (
    <FormContent>
      <form
        action="/obrigado"
        method="post"
        name="formOrcamento2"
        onSubmit={sendEmail}
      >
        <div>
          <div className="form-group">
            <label>Qual o seu nome? *</label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite o nome completo"
              name="nomeCompleto"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Qual o seu e-mail? *</label>
            <input
              className="form-control"
              type="email"
              placeholder="Digite o e-mail"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Qual o seu telefone? *</label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite o telefone"
              name="telefone"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>
              Qual a empresa que você trabalha ou e/ou é dono que precisa de
              videomonitoramento? *
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite o nome da empresa"
              name="nomeEmpresa"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Qual o seu cargo na empresa? *</label>
            <select className="form-control" name="cargo">
              <option>Selecione uma opção abaixo</option>
              <option value="DIRETOR">Diretor/CEO</option>
              <option value="GERENTE">Gerente Administrativo</option>
              <option value="FUNCIONARIO">Funcionário</option>
              <option value="OUTRO">Outro</option>
            </select>
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>
              Quantos andares, níveis ou parques contemplam o seu Shopping? *
            </label>
            <select className="form-control" name="andares">
              <option>Selecione uma opção abaixo</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Cada andar ou nível, em média, tem quantas vagas? *</label>
            <select className="form-control" name="vagas">
              <option>Selecione uma opção abaixo</option>
              <option value="_100">Até 100 vagas</option>
              <option value="100_200">Entre 100 e 200 vagas</option>
              <option value="200_300">Entre 200 e 300 vagas</option>
              <option value="300_400">Entre 300 e 400 vagas</option>
              <option value="500">Mais de 500</option>
            </select>
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>
              O sistema é oferecido com 24 horas de gravação incluso. Você
              desejaria de mais dias para o registro?
            </label>
            <input
              className="form-control"
              type="number"
              placeholder=" Digite o número de dias, caso você precisar:"
              name="diasGravacao"
            />
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>
              Quantos painéis de sinalização de vagas internas você gostaria de
              instalar? *
            </label>
            <select className="form-control" name="paineis">
              <option>Selecione uma opção abaixo</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Deseja enviar uma mensagem adicional? *</label>
            <textarea
              name="mensagem"
              placeholder="Escreva a mensagem"
            ></textarea>
          </div>
        </div>
        <div className="col-12">
          <button type="submit">SOLICITAR PROPOSTA</button>
        </div>
      </form>
    </FormContent>
  );
}
