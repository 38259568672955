import React from 'react'
import { FaDotCircle } from 'react-icons/fa'
import Slider from '../../../assets/background_slider.jpg'
import { Content, Main } from './styles'

export default function Prices() {
  return (
    <Main>
      <img src={Slider} alt='' srcSet='' />
      <Content>
        <div className='header'>
          <h1>Nossos Valores</h1>
          <p>
            Sempre buscando prover os melhores produtos e serviços para nossos clientes, a PolliPark
            segue as seguintes diretrizes:
          </p>
        </div>

        <div className='prices'>
          <div className='otimize left'>
            <div className='font'>
              <FaDotCircle />
            </div>
            <h1>OTIMIZAÇÃO</h1>
            <p>Queremos otimizar o seu investimento com o mais breve e maior retorno possível</p>
          </div>
          <div className='otimize right'>
            <div className='font'>
              <FaDotCircle />
            </div>
            <h1>DINAMISMO</h1>
            <p>
              Mais que um sinalizador de estacionamento, a PolliPark oferece uma solução completa e
              dinâmica
            </p>
          </div>
          <div className='otimize left'>
            <div className='font'>
              <FaDotCircle />
            </div>
            <h1>EFICIÊNCIA</h1>
            <p>
              Sistema de gestão para você controlar o que as câmeras captam de informação sobre o
              seu estacionamento
            </p>
          </div>
          <div className='otimize right'>
            <div className='font'>
              <FaDotCircle />
            </div>
            <h1>FORÇA</h1>
            <p>
              Com o apoio da empresa espanhola ParkEyes pertencente ao TKH GROUP, a PolliPark já
              nasceu com robustez e alta tecnologia
            </p>
          </div>
          <div className='otimize left'>
            <div className='font'>
              <FaDotCircle />
            </div>
            <h1>INOVAÇÃO</h1>
            <p>
              Sempre criando novas tecnologias que completem a necessidade do mercado nacional
              Brasileiro
            </p>
          </div>
        </div>
      </Content>
    </Main>
  )
}
