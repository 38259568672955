import React from 'react'
import Light from '../../../../../assets/cards/slider2.jpg'
import Clpr from '../../../../../assets/cards/foto_nova.jpg'
import Inspec from '../../../../../assets/cards/custo2.jpg'
import { Cards } from './styles'

export default function Card() {
  return (
    <Cards>
      <div className='content'>
        <img src={Light} alt='' />
        <div>
          <h1>Agilidade ao estacionar</h1>
          <p>
            A sinalização em LED facilita ao cliente visualizar onde há vagas especiais e vagas
            disponíveis. Ofereça facilidade agregando valor ao seu empreendimentoo.
          </p>
        </div>
      </div>
      <div className='content'>
        <img src={Clpr} alt='' />
        <div>
          <h1>Melhoria no fluxo de veículos</h1>
          <p>
            Com a facilidade para o cliente encontrar onde há vagas, o congestionamento e filas em
            seu estacionamento irá diminuir, aumentando o fluxo de carros para o seu empreendimento.
          </p>
        </div>
      </div>
      <div className='content'>
        <img src={Inspec} alt='' />
        <div>
          <h1>Baixo custo</h1>
          <p>
            A iluminação em LED consome pouca energia e têm pouco impacto na conta de luz do seu
            empreendimento, o que facilita na gestão do custo e análise de lucratividade de toda a
            estrutura.
          </p>
        </div>
      </div>
    </Cards>
  )
}
