import React from 'react'

import SliderContent from './SliderContainer'
import Services from './Services'
import Functionality from './functionality'
import Contact from './Contact'
import AboutUs from './AboutUs'
import Flags from './Flags'
import Photos from './galeria'

function Home() {
  return (
    <>
      <SliderContent />
      <Services />
      <Functionality />
      <Contact />
      <AboutUs />
      <Flags />
      <Photos />
    </>
  )
}

export default Home
