import React from 'react'
import { BsLinkedin, BsInstagram } from 'react-icons/bs'
import Logo from '../../assets/logopollipark_branco4.png'
import { Link } from 'react-router-dom'
import { EndFooter, FooterMainHeader } from './styles'
import Help from './Help'

export default function Footer() {
  return (
    <>
      <Help />
      <FooterMainHeader>
        <div className='left'>
          <img src={Logo} alt='' srcSet='' />
          <p>
            A PolliPark oferece o melhor sistema de sinalização de vagas com vídeo-monitoramento em
            tempo real para estacionamentos. Tenha maior segurança e facilidade em gestão para vagas
            do seu estabelecimento.
          </p>
        </div>
        <div className='right'>
          <h1>
            ENTRE EM <span style={{ color: '#474344' }}>CONTATO</span>
          </h1>
          <div className='email'>
            <p>Email:</p>
            <Link to='mailto:contato@pollipark.com.br'>contato@pollipark.com.br</Link>
          </div>
          <div className='social'>
            <Link to='https://www.linkedin.com/in/pollipark-um-jeito-seguro-de-estacionar-88b4371a0/' target='_blank'>
              <BsLinkedin style={{ color: '#ffffff' }} />
            </Link>
            <Link
              to='https://www.instagram.com/pollipark__/?igshid=YTQwZjQ0NmI0OA%3D%3D'
              target='_blank'
            >
              <BsInstagram style={{ color: '#ffffff' }} />
            </Link>
          </div>
          <h1>
            POLÍTICA DE <span style={{ color: '#474344' }}>PRIVACIDADE</span>
          </h1>
          <Link to='/privacidade' style={{ color: '#ffffff' }}>
            Acesse A Nossa Política De Privacidade aqui
          </Link>
        </div>
      </FooterMainHeader>

      <EndFooter>
        <p>PolliPark 2021 © Todos os direitos reservados.</p>
      </EndFooter>
    </>
  )
}
