import styled from "styled-components";

export const Header = styled.header`
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(71, 67, 68, 0.8);
    font-family: "Poppins";
    color: #ffffff;
    height: 300px;

    @media (max-width: 620px) {
      height: 200px;
    }

    h1 {
      position: absolute;
      font-size: 30px;
    }

    img {
      width: 100%;
      height: 100%;
      z-index: -2;
    }
  }
`;
export const ServiceMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  background-color: #f6f6f6;
  gap: 50px;

  img {
    width: 400px;
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 20px;
    padding: 40px;
    @media (max-width: 1000px) {
      width: 100%;
    }

    h1 {
      font-weight: 700;
      font-size: 30px;
      color: #474344;
    }

    h4 {
      font-weight: 400;
      font-size: 18px;
      color: #777;
      text-align: justify;
    }
    p {
      color: #666666;
      font-weight: 800;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    width: 40%;
    align-items: center;

    @media (max-width: 1000px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }
`;
