import styled from 'styled-components'
import polli from '../../assets/page-banner-bg.jpg'

export const Main = styled.div`
  text-align: center;
  color: #474344;
  font-family: 'Poppins';
  font-weight: 700;
  margin: 50px 0;
`
export const Header = styled.header`
  background-image: url(${polli});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 60px;
    justify-content: center;
    align-items: center;
    background-color: rgba(71, 67, 68, 0.8);
    font-family: 'Poppins';
    color: #ffffff;

    h1 {
      font-size: 40px;
    }
  }
`
